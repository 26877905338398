import ShopItemForm from "../../components/forms/ShopItemForm";
import ApiService from "../../helpers/api";
import FormLayout from "../../components/layout/FormLayout";

const Component = () => {
	return (
		<FormLayout pageTitle="Shop Form" backTo={"shop-item"}>
			<ShopItemForm />
		</FormLayout>
	);
};
const loader = async ({ request, params: { guid, itemId } }) => {
	try {
		const loaderData = await ApiService.get_categories();
		const resourceValue = [];
		const resource = await ApiService.getAllresource();
		if (!loaderData.data && !resource.data) {
			throw new Response.error();
		}
		resource.data.forEach(el => {
			if (!el.isFragmental) {
				resourceValue.push({
					value: el.id,
					label: el.name,
				});
			}
		});
		if (itemId) {
			const item = await ApiService.getShopItem(itemId);
			let infoCategory;
			if (item.data.categoryId)
				infoCategory = await ApiService.get_category_id(item.data.categoryId);
			return {
				loaderData: loaderData.data,
				item: item.data,
				infoCategory: infoCategory.data,
				resourceValue,
			};
		}
		return { loaderData: loaderData.data, resourceValue };
	} catch (error) {
		return error;
	}
};
const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();

		if (itemId) {
			const data = await ApiService.updateShopItem(requestData);
			if (data.data && data.status === 200) {
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_shopItem(requestData);
		return data || [];
	} catch (error) {}
};
const ShopItemForms = Object.assign(Component, { action, loader });

export default ShopItemForms;
