import { Table } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link, useFormAction, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import React, { useContext, useEffect } from "react";
import AlertService from "../../helpers/alertService";
import { useAppSubmit } from "../../helpers/hooks";
import AddButton from "../../components/butons/AddButton";
import NoData from "./../../components/noData/NoData";
import moment from "moment";
import { TimerContext } from "../../App";

const getType = type => {
	switch (type) {
		case 0:
			return "Individual";
		case 1:
			return "Group";
		case 2:
			return "Cluster";
		default:
			return "";
	}
};

const Component = () => {
	const data = useLoaderData();
	const submit = useAppSubmit(),
		action = useFormAction();
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		if (data) {
			setIsUpdateTimer(true);
		}
	}, [data]);

	const onSubmit = async id => {
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`).then(res => {
			onSubmit(id);
		});

	return (
		<div>
			<AddButton pageTitle="Leaderbords" buttonTitle="leaderbord" to={"form"} />
			{Array.isArray(data) && data.length ? (
				<Table bordered responsive className={` mt-3 `}>
					<thead>
						<tr>
							<th style={{ width: "10px" }}>#</th>
							<th>Name</th>
							<th>Description</th>
							<th>Type</th>
							<th>Resource</th>
							<th>Start date</th>
							<th>End date</th>
							<th style={{ width: 100 }} className="align-middle">
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{!!data &&
							data?.map((item, index) => {
								return (
									<React.Fragment key={item.id}>
										<tr>
											<td>{index + 1}</td>
											<td>{item.name}</td>
											<td>{item.description}</td>
											<td>{getType(item.type)}</td>
											<td>{item?.resource?.name}</td>
											<td>
												{item?.startDate
													? moment(item?.startDate).format("DD.MM.YYYY")
													: ""}
											</td>
											<td>
												{item?.startDate
													? moment(item?.endDate).format("DD.MM.YYYY")
													: ""}
											</td>
											<td className="d-flex gap-3 justify-content-center button_container">
												<div className="button_svg sucsses_boredr">
													<Link to={`form/${item.id}`}>
														<AiOutlineEdit size={21} color="#198754" />
													</Link>
												</div>
												<div
													className="button_svg error_boredr"
													onClick={() => {
														show(item.id);
													}}>
													<AiOutlineDelete size={21} color="#dc3545" />
												</div>
											</td>
										</tr>
									</React.Fragment>
								);
							})}
					</tbody>
				</Table>
			) : (
				<NoData />
			)}
		</div>
	);
};
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.delete_leaderboard(id);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return id;
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { itemId } }) => {
	try {
		const data = await ApiService.get_leaderboard();
		return data?.data || [];
	} catch (error) {
		return error;
	}
};

const Leaderbord = Object.assign(Component, { loader, action });
export default Leaderbord;
