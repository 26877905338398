import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import { FailUpLoad } from "../../helpers/helper";
import { Button, Col, Row } from "react-bootstrap";
import { useError } from "../../helpers/hooks";
import Select from "react-select";

const validFileTypes = ["jpg", "png", "jpeg", "JPEG", "JPG", "PNG"];
const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: state.isFocused
			? "86b7fe"
			: state.selectProps.error
			? "red"
			: "gray",
		boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(13,110,253,.25)" : "",
	}),
};
const CustomOption = props => (
	<>
		<div
			{...props.innerProps}
			className="d-flex justify-content-center cursor-pointer"
			style={{ backgroundImage: `url(${props.label})` }}>
			<img
				src={props.label}
				style={{ objectFit: "contain", width: "150px", height: "150px" }}
			/>
		</div>
		{props &&
			props.options.length &&
			!(props.options[props.options.length - 1].value === props.data.value) && (
				<hr />
			)}
	</>
);
const UplodImg = ({
	action = () => {},
	regName,
	regName2 = "",
	className = "",
	value = "",
	multiple = false,
	id = "icon-button-file",
	labelValue = "Image",
	required = false,
	options = [],
	isFullScreen = false,
}) => {
	const errorMessage = useError(regName);
	const errorMessage2 = useError(regName2);
	const [defaultImg, setDefaultImg] = useState(false);
	const [file, setFail] = useState("");
	useEffect(() => {
		setFail(value);
	}, [value]);
	return (
		<>
			<div className={`${className}`}>
				{!file && (
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							id="flexCheckDefault"
							onChange={e => {
								setDefaultImg(e.target.checked);
							}}
						/>
						<label className="form-check-label" htmlFor="flexCheckDefault">
							Default images
						</label>
					</div>
				)}
				<input
					color="primary"
					type="file"
					multiple={multiple}
					onChange={async e => {
						const fileName = await new FailUpLoad().readFile(
							e.target.files[0],
							validFileTypes,
						);
						setFail(fileName);
						action(regName2, "", {
							shouldValidate: true,
							shouldDirty: true,
						});

						action(regName, e.target.files[0], {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					id={id}
					accept=".jpg,.png,jpeg,.JPEG,.JPG,.PNG,"
					style={{ display: "none" }}
				/>
				{labelValue ? (
					<label className="mb-2 form-label">
						{labelValue}
						{required ? <span className="required ms-1">*</span> : ""}
					</label>
				) : null}
				<br />
				{!file && !defaultImg && (
					<>
						<Button
							variant="primary"
							style={{ cursor: "pointer !important" }}
							className={`d-flex align-items-center px-3 ${
								!!errorMessage || !!errorMessage2 ? "button_error" : ""
							}`}
							onClick={() => {
								if (!id) return false;
								document.getElementById(id)?.click();
							}}>
							<AiOutlineDownload size={21} className="me-2" />
							<span>Upload</span>
						</Button>
					</>
				)}
				{!file && defaultImg && (
					<Row>
						<Col md={isFullScreen ? 12 : 6}>
							<div className="form-group mb-3">
								<Select
									className="select"
									defaultValue={value}
									options={options}
									error={errorMessage}
									styles={customStyles}
									components={{ Option: CustomOption }}
									onChange={e => {
										setFail(e.label);
										action(regName2, e.value, {
											shouldValidate: true,
											shouldDirty: true,
										});

										action(regName, "", {
											shouldValidate: true,
											shouldDirty: true,
										});
									}}
								/>
							</div>
						</Col>
					</Row>
				)}
				{!!file && (
					<div className="position-relative d-inline-flex  flex-column p-2 border rounded">
						<img
							className="uplod_img py-1"
							src={file}
							srcSet={file}
							alt="file"
						/>
						{/* <div
						className="position-absolute  cursor-pointer"
						style={{
							top: 5,
							right: 5,
						}}
						onClick={() => {
							setFail("");
							action(regName2, "", {
								shouldValidate: true,
								shouldDirty: true,
							});
							action(regName, "", {
								shouldValidate: true,
								shouldDirty: true,
							});
						}}>
						<AiOutlineDelete size={29} color="#dc3545" />
					</div> */}
						<Button
							size="sm"
							onClick={() => {
								setFail("");
								action(regName2, "", {
									shouldValidate: true,
									shouldDirty: true,
								});
								action(regName, "", {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							variant="outline-danger">
							Remove
						</Button>
					</div>
				)}
			</div>

			{(!!errorMessage || errorMessage2) && !file && (
				<span className="helper_text">{errorMessage || errorMessage2}</span>
			)}
		</>
	);
};

export default UplodImg;
