import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useFormAction, useLoaderData, useParams } from "react-router-dom";
import { useAppSubmit } from "../../../helpers/hooks";
import CustomsInput from "../../Inputs/CustomsInput";
import UplodImg from "../../Inputs/UplodImg";
import CustomSelect from "../../Inputs/CustomSelect";
import ActionButton from "../../butons/ActionButton";
import { badgeFormValidation } from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const BadgeForm = ({ badgeSelectValue, setData, data }) => {
	const { badgesImg } = useLoaderData();
	const { itemId, guid } = useParams();
	const formMethods = useForm({
		defaultValues: {
			name: data?.name || "",
			description: data?.description || "",
			imageName: data?.imageName || "",
			image: "",
			dependantBadgeIds: [],
			deleteBadgeIds: [],
		},
		resolver: yupResolver(badgeFormValidation),
	});
	const submit = useAppSubmit(),
		action = useFormAction();
	const {
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = formMethods;
	const onSubmit = _data => {
		const formData = new FormData();
		Object.entries(_data).forEach(([key, value]) => {
			if (!value) return formData.append(key, "");
			if (Array.isArray(value)) {
				value.forEach(el => {
					formData.append(key, el);
				});
			} else {
				formData.append(key, value);
			}
		});
		if (itemId) {
			formData.append("id", itemId);
		}
		submit(formData, { method: "POST", action });
	};
	const onChange = (_, action) => {
		if (action.action === "select-option") {
			const newValue = [...watch("dependantBadgeIds"), action.option.value];
			setValue("dependantBadgeIds", newValue);
			return;
		}
		if (action.action === "remove-value") {
			const newValue = watch("dependantBadgeIds").filter(
				el => el !== action.removedValue.value,
			);
			if (
				data?.dependantBadge.find(el => el.id === action.removedValue.value)
			) {
				const newDependantBadge = data?.dependantBadge.filter(
					el => el.id !== action.removedValue.value,
				);
				setData({ ...data, dependantBadge: newDependantBadge });
				const newDeleteBadgeIds = [
					...watch("deleteBadgeIds"),
					action.removedValue.value,
				];
				setValue("deleteBadgeIds", newDeleteBadgeIds);
			}
			return;
		}
		if (action.action === "clear") {
			if (data?.dependantBadge?.length) {
				const newDeleteBadgeIds = [
					...watch("deleteBadgeIds"),
					...data?.dependantBadge.map(el => el.id),
				];
				setValue("deleteBadgeIds", newDeleteBadgeIds);
			}
			setData({ ...data, dependantBadge: [] });
			setValue("dependantBadgeIds", []);
		}
	};
	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						<CustomsInput
							regName={"name"}
							type="text"
							required={true}
							label={"Badge name"}
							placeholder={"Name"}
						/>
						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						<Row>
							<Col md={6}>
								<CustomSelect
									onChange={onChange}
									isMulti={true}
									defaultValues={data?.dependantBadge}
									options={badgeSelectValue}
									regName={`dependantBadgeIds`}
									label={"Dependent badges"}
								/>
							</Col>
						</Row>
						<UplodImg
							options={badgesImg}
							imageNameRegname={""}
							action={setValue}
							value={data?.imagePath || ""}
							labelValue="Badge Image"
							required="true"
							regName={"image"}
							regName2="imageName"
						/>
						<ActionButton buttonName="Save" />
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
};
export default BadgeForm;
