import {
	useActionData,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import ApiService, { baseURL } from "../../helpers/api";
import { useContext, useEffect, useState } from "react";
import BadgeResources from "../../components/forms/badge/BadgeResources";
import BadgeForm from "../../components/forms/badge/BadgeForm";
import BadgeQuest from "../../components/forms/badge/BadgeQuest";
import BadgeFragments from "../../components/forms/badge/BadgeFragments";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";

const Component = ({}) => {
	const { itemId, guid } = useParams();
	const { data, resourceValue, badgeValue, questValue, fragmentsValue } =
		useLoaderData();
	const test = useLoaderData();
	const [_data, setData] = useState(data);
	const actiondata = useActionData();
	const navigate = useNavigate();
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {
		if (resourceValue) {
			setIsUpdateTimer(true);
		}
	}, [resourceValue]);
	useEffect(() => {
		if (actiondata === "update") {
			return navigate(`/badges/${guid}`);
		}
		if (actiondata && actiondata?.id) {
			navigate(`${actiondata?.id}`);
		}
	}, [actiondata]);

	return (
		<FormLayout pageTitle="Badge Form" backTo={"badges"}>
			<BadgeForm data={_data} badgeSelectValue={badgeValue} setData={setData} />
			{itemId && (
				<>
					<hr />
					<BadgeResources
						data={_data?.badgeResources}
						resourceSelectValue={resourceValue}
						setData={setData}
					/>
					<BadgeQuest
						setData={setData}
						data={_data?.badgeQuests}
						questSelectValue={questValue}
					/>
					<BadgeFragments
						setData={setData}
						data={_data?.badgeFragments}
						fragmentsValue={fragmentsValue}
					/>
				</>
			)}
		</FormLayout>
	);
};
const loader = async ({ request, params: { guid, itemId } }) => {
	try {
		let lodardata = {};
		let data;
		const resource = await ApiService.getAllresource();
		const fragmentsValue = await ApiService.get_all_fragments().then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const badge = await ApiService.get_all_badge();
		const questValue = await ApiService.get_all_quest().then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const badgesImg = await ApiService.getStaicFailes("Badge").then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => {
					return {
						value: el,
						label: `${baseURL}/${el}`,
					};
				});
			} else return [];
		});
		if (
			!resource.data &&
			!fragmentsValue.length &&
			!badge.data &&
			!questValue.length
		) {
			throw new Response("Not Found", { status: 404 });
		}
		const resourceValue = [];
		const badgeValue = [];

		badge.data.forEach(el => {
			if (el.id !== +itemId) {
				badgeValue.push({
					value: el.id,
					label: el.name,
				});
			}
		});

		resource.data.forEach(el => {
			if (!el.isFragmental) {
				resourceValue.push({
					value: el.id,
					label: el.name,
				});
			}
		});
		if (itemId) {
			data = await ApiService.get_badge(itemId);
			if (!data.data) {
				throw new Response("Not Found", { status: 404 });
			}
			lodardata = {
				data: data.data,
			};
		}
		lodardata = {
			...lodardata,
			resourceValue: resourceValue,
			badgeValue: badgeValue,
			questValue: questValue,
			fragmentsValue,
			badgesImg,
		};
		return lodardata;
	} catch (error) {
		return error;
	}
};
const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();

		if (itemId) {
			const data = await ApiService.update_badge(requestData);
			if (data.data && data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.craeate_badge(requestData);
		if (data.data && data.status === 200) {
			AlertService.alert("success", "Data seved");
			return data?.data || [];
		}
		return null;
	} catch (error) {}
};

const BadgeForms = Object.assign(Component, { action, loader });
export default BadgeForms;
