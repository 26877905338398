import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import {
	useActionData,
	useFormAction,
	useLoaderData,
	useNavigation,
} from "react-router-dom";
import { useAppSubmit } from "../../../helpers/hooks";
import CustomsInput from "../../Inputs/CustomsInput";
import UplodImg from "../../Inputs/UplodImg";
import { useContext, useEffect } from "react";
import { questFormValidation } from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimerContext } from "../../../App";

const keys = ["name", "description", "imageName"];

const QuestForm = ({ data, setData }) => {
	const { questImg } = useLoaderData();
	const actiondata = useActionData(),
		submit = useAppSubmit(),
		action = useFormAction(),
		navigation = useNavigation();
	const formMethods = useForm({
		defaultValues: {
			name: data?.name || "",
			description: data?.description || "",
			imageName: data?.imageName || "",
			image: "",
		},
		resolver: yupResolver(questFormValidation),
	});

	const { handleSubmit, setValue, reset } = formMethods;
	useEffect(() => {
		if (data) {
			keys.forEach(key => {
				setValue(key, data[key]);
			});
		}
	}, [data, keys, setValue]);
	useEffect(() => {
		if (actiondata === "delete") {
			reset();
			setData({});
		}
	}, [actiondata]);
	const onSubmitDelete = id => {
		const formData = new FormData();
		formData.append("method", "delete");
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const onSubmit = _data => {
		const formData = new FormData();
		Object.entries(_data).forEach(([key, value]) => {
			formData.append(key, value);
		});
		if (data?.id) {
			formData.append("id", data?.id);
		}
		submit(formData, { method: "POST", action });
	};
	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						<CustomsInput
							type="text"
							regName={"name"}
							label={"Quest name"}
							required={true}
							placeholder={"Deafault name from entity of partner platform"}
						/>
						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						{/* <CustomSelect regName={`entityId`} cb={setValue} label={"Entity"} /> */}
						<UplodImg
							options={questImg}
							className="mb-3"
							action={setValue}
							value={data?.imagePath || ""}
							labelValue="Image"
							regName={"image"}
							regName2="imageName"
							required={true}
						/>
						<div className="d-flex justify-content-end">
							<Button
								disabled={navigation?.state === "submitting"}
								type="submit"
								className="me-3"
								variant="primary">
								{navigation?.state === "submitting" ? (
									<span className="d-flex align-items-center gap-2">
										<span>Loading...</span>
										<Spinner animation="border" role="status" size="sm" />
									</span>
								) : (
									"Save"
								)}
							</Button>
							{data?.id && (
								<Button
									disabled={navigation?.state === "submitting"}
									type="submit"
									onClick={() => {
										onSubmitDelete(data?.id);
									}}
									variant="danger">
									Delete
								</Button>
							)}
						</div>
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
};

export default QuestForm;
