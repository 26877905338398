import { Button, Card, Table } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import {
	Link,
	useActionData,
	useFormAction,
	useLoaderData,
} from "react-router-dom";
import ApiService from "../../helpers/api";
import { useAppSubmit } from "../../helpers/hooks";
import AlertService from "../../helpers/alertService";
import CardsBadges from "../../components/card/CardsBadges";
import AddButton from "../../components/butons/AddButton";
import { useContext, useEffect } from "react";
import { TimerContext } from "../../App";

const Component = () => {
	const submit = useAppSubmit(),
		action = useFormAction(),
		actiondata = useActionData();
	const onSubmit = async id => {
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const data = useLoaderData();
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {
		if (data) {
			setIsUpdateTimer(true);
		}
	}, [data]);

	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`).then(res => {
			onSubmit(id);
		});
	return (
		<div>
			<AddButton buttonTitle="badge" to="form" pageTitle="Badges" />
			<CardsBadges show={show} data={data} />
		</div>
	);
};
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.delete_badge(id);
		AlertService.alert("success", "Data seved");
		return true;
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const data = await ApiService.getByEntity();
		return data.data;
	} catch (error) {
		return error;
	}
};
const Badges = Object.assign(Component, { loader, action });
export default Badges;
