import { Form } from "react-bootstrap";
import { useError, useFormRegister } from "../../helpers/hooks";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useRef, useState } from "react";
import moment from "moment";

const CustomsInput = ({
	regName,
	index,
	type = "text",
	label,
	required,
	placeholder,
	rows = 3,
	props,
	blockClassName = "",
	maxDate = false,
	maiDate = false,
}) => {
	const errorMessage = useError(regName, index);
	const register = useFormRegister(regName);
	const [isShow, setIsShow] = useState(true);
	const ref = useRef();
	if (type === "date") {
		return (
			<Form.Group
				className={`mb-3 input_contaoner ${blockClassName} ${
					errorMessage ? "error" : ""
				}`}>
				<Form.Label className="text-muted">
					{label}
					{required ? <span className="required ms-1">*</span> : ""}
				</Form.Label>
				<Form.Control
					type={type}
					{...register}
					{...props}
					min={
						maiDate ? moment(new Date("2021-01-01")).format("YYYY-MM-DD") : ""
					}
					// placeholder={placeholder}
				/>
				{!!errorMessage && <span className="helper_text">{errorMessage}</span>}
			</Form.Group>
		);
	}
	if (type === "password") {
		return (
			<Form.Group
				className={`mb-3 input_contaoner ${blockClassName} ${
					errorMessage ? "error" : ""
				} position-relative`}>
				<Form.Label className="text-muted">
					{label}
					{required ? <span className="required ms-1">*</span> : ""}
				</Form.Label>
				<div className="position-relative">
					<Form.Control
						type={isShow ? "password" : "text"}
						{...register}
						{...props}
						// placeholder={placeholder}
						// onChange={e => {
						// 	let reg = new RegExp("!/^[0-9]+$/");
						// 	if (reg.test(e.target.value)) {
						// 		register.onChange(e);
						// 	}
						// }}
					/>
					<div
						style={{
							width: 51,
						}}
						className="position-absolute top-50 start-100 translate-middle show "
						onClick={() => setIsShow(!isShow)}>
						{!isShow ? <FaEye /> : <FaEyeSlash />}
					</div>
				</div>

				{!!errorMessage && <span className="helper_text">{errorMessage}</span>}
			</Form.Group>
		);
	}

	if (type === "textarea") {
		return (
			<Form.Group
				className={`mb-3 input_contaoner ${blockClassName} ${
					errorMessage ? "error" : ""
				}`}>
				<Form.Label className="text-muted">
					{label}
					{required ? <span className="required ms-1">*</span> : ""}
				</Form.Label>
				<Form.Control
					className="textarea"
					as={type}
					rows={rows}
					{...register}
					{...props}
					// placeholder={placeholder}
				/>
				{!!errorMessage && <span className="helper_text">{errorMessage}</span>}
			</Form.Group>
		);
	} else
		return (
			<>
				<Form.Group
					className={`mb-3 input_contaoner ${blockClassName} ${
						errorMessage ? "error" : ""
					}`}>
					<Form.Label className="text-muted">
						{label}
						{required ? <span className="required ms-1">*</span> : ""}
					</Form.Label>
					<Form.Control
						type={type}
						{...register}
						{...props}
						// placeholder={placeholder}
					/>
					{!!errorMessage && (
						<span className="helper_text">{errorMessage}</span>
					)}
				</Form.Group>
			</>
		);
};

export default CustomsInput;
