import React, { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Button, Card, Modal, Table, Form, Spinner } from "react-bootstrap";
import ApiService from "../../../helpers/api";
import CustomSelect from "../../Inputs/CustomSelect";
import AlertService from "../../../helpers/alertService";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { validetionFunctions } from "../../../helpers/validation";
import { array } from "i/lib/util";
import { TimerContext } from "../../../App";
const uniqueId = () => Math.random().toString();

const BadgeFragments = ({ data = [], fragmentsValue, setData }) => {
	const { itemId, guid } = useParams();
	const { setIsUpdateTimer } = useContext(TimerContext);
	const [open, setOpen] = useState(false);
	const [shoewSelatc, setShowSelect] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [defaultValues, setDefaultValues] = useState({
		badgeId: itemId,
		fragmentId: "",
	});
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(
			object().shape({
				fragmentId: validetionFunctions.select,
			}),
		),
	});
	const {
		handleSubmit,
		setValue,
		watch,
		register,
		// formState: { errors },
	} = formMethods;
	const tableData = useMemo(() => {
		return data?.map(el => {
			const value = fragmentsValue?.find(item => item.value === el?.fragmentId);
			return {
				...el,
				...value,
			};
		});
	}, [data, fragmentsValue]);
	const onSubmit = async _data => {
		setIsLoading(true);
		await ApiService.create_badge_fragment(_data)
			.then(res => {
				if (res.data) {
					AlertService.alert("success", "Data seved");
					setData(prev => {
						const newData = [...data, res.data];
						return {
							...prev,
							badgeFragments: newData,
						};
					});
					setIsUpdateTimer(true);
				}
				onClose();
			})
			.catch(error => {
				Object.keys(error).forEach(key => {
					if (Array.isArray(error[key])) {
						error[key].forEach(el => {
							AlertService.alert("warning", el);
						});
					}
				});
			})
			.finally(() => {
				setIsLoading(false);
				// setLoad(false);
			});

		// submit(formData, { method: "POST", action });
	};
	const onClose = () => {
		setOpen(!open);
		setShowSelect(true);
		setDefaultValues({
			badgeId: itemId,
			questId: "",
			isRequired: false,
		});
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				ApiService.delete_badge_fragment(id)
					.then(res => {
						if (res.status === 200) {
							AlertService.alert("success", "Data seved");
							setData(prev => {
								let newData = data?.filter(el => el.id !== id);
								return {
									...prev,
									badgeFragments: newData,
								};
							});
							setIsUpdateTimer(true);
						}
					})
					.catch(error => {
						AlertService.alert("error", "Sorry, something went wrong!");
					})
					.finally(() => {});
			})
			.catch(() => {});
	// badgeFragments;
	if (itemId) {
		return (
			<>
				<Modal
					show={open}
					onHide={onClose}
					backdrop="static"
					centered
					keyboard={false}>
					<FormProvider {...formMethods}>
						<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{"Create"}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<CustomSelect
									required={true}
									cb={setValue}
									options={fragmentsValue}
									regName={`fragmentId`}
									label={"Fragment"}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button
									disabled={isLoading}
									variant="secondary"
									onClick={onClose}>
									Close
								</Button>
								<Button disabled={isLoading} type="submit" variant="primary">
									{isLoading ? (
										<span className="d-flex align-items-center gap-2">
											<span>Loading...</span>
											<Spinner animation="border" role="status" size="sm" />
										</span>
									) : (
										"Save"
									)}
								</Button>
							</Modal.Footer>
						</Form>
					</FormProvider>
				</Modal>
				<div className="d-flex justify-content-between align-items-center p-2">
					<h5>Fragments</h5>
					<Button
						variant="light"
						onClick={() => {
							setOpen(true);
						}}>
						<MdOutlineAddCircleOutline size={21} />
					</Button>
				</div>
				<div>
					<Table
						bordered
						responsive
						style={{
							backgroundColor: "#fff",
						}}
						className={`text-center mt-3`}>
						<thead>
							<tr>
								<th style={{ width: "10px" }} className="align-middle">
									#
								</th>
								<th className="align-middle">Name</th>
								<th style={{ width: 100 }} className="align-middle">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{tableData?.map((item, index) => {
								return (
									<React.Fragment key={item.id}>
										<tr>
											<td className="align-middle">{index + 1}</td>
											<td className="align-middle">{item.label}</td>

											<td className="d-flex gap-1 w-full justify-content-around align-items-center button_container">
												<div
													className="button_svg error_boredr"
													onClick={() => {
														show(item.id);
													}}>
													<AiOutlineDelete size={21} color="#dc3545" />
												</div>
											</td>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</Table>
				</div>
			</>
		);
	}
	return null;
};

export default BadgeFragments;
