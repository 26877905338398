import { useContext, useEffect, useState } from "react";
import {
	useActionData,
	useFormAction,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import Select from "react-select";
import CustomsInput from "../Inputs/CustomsInput";
import CustomSelect from "../Inputs/CustomSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { shopItemFormValidation } from "../../helpers/validation";
import { useAppSubmit } from "../../helpers/hooks";
import ApiService from "../../helpers/api";
import MultipleImageInput from "../Inputs/MultipleImageInput";
import ActionButton from "../butons/ActionButton";
import moment from "moment";
import { TimerContext } from "../../App";

const randomID = () => Math.random().toString();

export default function ShopItemForm() {
	const { loaderData, item, infoCategory, resourceValue } = useLoaderData();
	const { guid, itemId } = useParams();
	const [data, setData] = useState([loaderData]);
	const [selectValue, setSelectValue] = useState([]);
	const [selectOptions, setSelectOptions] = useState([]);
	const [showData, setShowData] = useState([]);
	const submit = useAppSubmit(),
		action = useFormAction();
	const actiondata = useActionData();
	const navigate = useNavigate();
	const formMethods = useForm({
		defaultValues: {
			name: item?.name || "",
			description: item?.description || "",
			imageNames: item?.imageNames || "",
			images: "",
			resourceAmount: item?.resourceAmount || "",
			availability: item?.availability || "",
			startDate: item?.startDate
				? moment(new Date(item?.startDate)).format("YYYY-MM-DD")
				: "",
			endDate: item?.endDate
				? moment(new Date(item?.endDate)).format("YYYY-MM-DD")
				: "",
			enabled: item?.enabled || true,
			resourceId: item?.resourceId || "",
			categoryId: item?.categoryId || "",
			deleteImages: [],
		},
		resolver: yupResolver(shopItemFormValidation),
	});
	const {
		control,
		handleSubmit,
		setValue,
		watch,
		register,
		formState: { errors },
	} = formMethods;
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		if (loaderData) {
			setIsUpdateTimer(true);
		}
	}, [loaderData]);
	useEffect(() => {
		if (actiondata?.data) {
			navigate(`/shop-item/${guid}`);
		} else if (actiondata === "update") {
			navigate(`/shop-item/${guid}`);
		}
	}, [actiondata]);

	const imageNames = useWatch({ control, name: "imageNames" });
	const images = useWatch({ control, name: "images" });
	const deleteImages = useWatch({ control, name: "deleteImages" });
	const date = useWatch({ control, name: "startDate" });

	useEffect(() => {
		if (Array.isArray(loaderData)) {
			const optionsValue = loaderData.map(el => ({
				value: el.id,
				label: el.name,
			}));
			optionsValue.unshift({ label: "As Parent", value: null });
			setSelectOptions(prev => {
				return [optionsValue];
			});
		}
	}, [loaderData]);

	const onChange = async (e, index) => {
		let newSelectData = selectOptions.slice(0, index + 1);
		let newData = data.slice(0, index + 1);
		let newValue = selectValue.slice(0, index);
		if (!e || (e && !e.value)) {
			setSelectOptions(newSelectData);
			setData(newData);
			setSelectValue([]);
			setValue("parentId", "");
			return;
		}
		setSelectValue(prev => [...newValue, { ...e, index }]);
		await ApiService.get_categories(e.value)
			.then(res => {
				let optionsValue = [];
				if (res.data && res.data.length) {
					setData([...data, res.data]);
					optionsValue = res.data.map(el => ({
						value: el.id,
						label: el.name,
					}));
				} else setData(newData);
				if (optionsValue.length) {
					setSelectOptions([...newSelectData, optionsValue]);
				} else setSelectOptions(newSelectData);
			})
			.catch(err => {
				setSelectOptions(newSelectData);
			});
		setValue("categoryId", e.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const onSubmit = data => {
		const formData = new FormData();
		Object.entries(data).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((el, i) => {
					formData.append(key, el);
				});
			} else formData.append(key, value);
		});
		if (itemId) {
			formData.append("id", itemId);
		}
		submit(formData, { method: "POST", action });
	};

	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						{!!itemId && !!infoCategory && (
							<h5>Selected category {infoCategory?.name}</h5>
						)}
						{!itemId && (
							<div
								className="form-group mb-3 d-flex flex-column gap-2"
								key={randomID()}>
								<div></div>
								<label className="mb-2">
									{selectValue.length
										? selectValue[selectValue.length - 1].label
										: "Choose category"}
								</label>
								{selectOptions.map((item, i) => (
									<Select
										key={i}
										error={errors?.categoryId?.message}
										styles={{
											control: (provided, state) => ({
												...provided,
												borderColor: state.isFocused
													? "86b7fe"
													: state.selectProps.error
													? "red"
													: "gray",
												boxShadow: state.isFocused
													? "0 0 0 0.25rem rgba(13,110,253,.25)"
													: "",
											}),
										}}
										className=""
										options={item}
										defaultValue={selectValue[i]}
										onChange={e => {
											onChange(e, i);
										}}
									/>
								))}
								{!!errors?.categoryId?.message && (
									<span className="helper_text">
										{errors?.categoryId?.message}
									</span>
								)}
							</div>
						)}

						<CustomsInput
							regName={"name"}
							type="text"
							required={true}
							label={"Name"}
							placeholder={"Name"}
						/>
						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						{/* <CustomSelect cb={setValue} regName={`Enabled`} label={"Enabled"} /> */}
						<Row className="align-items-center">
							<Col md={6}>
								<CustomSelect
									required={true}
									cb={setValue}
									defaultValues={item?.resource?.id || ""}
									regName={`resourceId`}
									label={"Resource"}
									options={resourceValue}
								/>
							</Col>
							<Col md={6}>
								<CustomsInput
									regName={"resourceAmount"}
									type="number"
									required={true}
									label={"Resource amount"}
									placeholder={"Resource amount"}
								/>
							</Col>
							<Col md={6}>
								<CustomsInput
									regName={"availability"}
									type="number"
									required={true}
									label={"Availability"}
									placeholder={"Availability"}
									blockClassName="mt-2"
								/>
							</Col>
							<Col md={6}>
								<Form.Label className="">Enabled</Form.Label>
								<Form.Check // prettier-ignore
									{...register("enabled")}
									type="switch"
									id="custom-switch"
									label="Yes"
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<CustomsInput
									regName={"startDate"}
									type="date"
									control={control}
									label={"Start date"}
									placeholder={"Category name"}
								/>
							</Col>
							<Col md={6}>
								<CustomsInput
									control={control}
									regName={"endDate"}
									type="date"
									label={"End date"}
									placeholder={"Category name"}
								/>
							</Col>
						</Row>
						<MultipleImageInput
							required={true}
							className="pb-2"
							action={setValue}
							imageNames={imageNames}
							deleteImages={deleteImages}
							deleteRegName="deleteImages"
							values={item?.imagePaths}
							images={images}
							regName={`images`}
							regName2="imageNames"
							labelValue="Image(s)"
						/>

						<ActionButton buttonName="Save" />
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
}
