import React from "react";
import { Button } from "react-bootstrap";
import { Link, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import CustomTable, {
	CustomAccordion,
} from "../../components/accordion/CustomAccordion";
import RecursiveTable from "../../components/table/RecursiveTable";
import AddButton from "../../components/butons/AddButton";
import AlertService from "../../helpers/alertService";

function Component(props) {
	return (
		<div>
			<AddButton
				pageTitle="Categories"
				buttonTitle="category"
				to={"form"}
				backTo="shop-item"
				isShowBackButton={true}
			/>
			<RecursiveTable />
		</div>
	);
}
const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.delete_category(id);
		AlertService.alert("success", "Data seved");
		return data?.data || [];
	} catch (error) {
		return error;
	}
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const category = await ApiService.get_categories();
		return category.data || [];
	} catch (error) {
		return error;
	}
};

const Category = Object.assign(Component, { loader, action });
export default Category;
