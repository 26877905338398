import { useActionData, useLoaderData } from "react-router-dom";
import ApiService, { baseURL } from "../../helpers/api";
import QuestFragments from "../../components/forms/quest/QuestFragments";
import QuestResources from "../../components/forms/quest/QuestResources";
import QuestForm from "../../components/forms/quest/QuestForm";
import { useContext, useEffect, useState } from "react";
import { toObject } from "../../helpers/helper";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";

const Component = () => {
	const loaderData = useLoaderData(),
		actiondata = useActionData();
	const [data, setData] = useState(loaderData?.data);
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {});
	useEffect(() => {
		if (loaderData?.data) {
			setData(loaderData.data);
			setIsUpdateTimer(true);
		}
	}, [loaderData]);
	return (
		<FormLayout pageTitle="Quest" isShow={false} className="">
			<QuestForm data={data} setData={setData} />
			{data && data?.id && (
				<>
					<hr />
					{data?.resources && (
						<QuestResources
							id={data?.id}
							data={data?.resources}
							resourceSelectValue={loaderData?.resourceValue}
							setData={setData}
						/>
					)}
					{data?.fragments && (
						<QuestFragments
							id={data?.id}
							data={data?.fragments}
							fragmentsValue={loaderData?.fragmentsValue}
							setData={setData}
						/>
					)}
				</>
			)}
		</FormLayout>
	);
};
const loader = async ({ request, params: { guid, id } }) => {
	try {
		const loaderData = await ApiService.get_quest();
		const resourceValue = await ApiService.getAllresource().then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const fragmentsValue = await ApiService.get_all_fragments().then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => ({ value: el.id, label: el.name }));
			}
			return [];
		});
		const questImg = await ApiService.getStaicFailes("Quest").then(res => {
			if (res.data && res.data.length) {
				return res.data.map(el => {
					return {
						value: el,
						label: `${baseURL}/${el}`,
					};
				});
			} else return [];
		});
		const _data = {
			data: loaderData?.data,
			fragmentsValue,
			resourceValue,
			questImg,
		};
		return _data;
	} catch (error) {
		return error;
	}
};
const action = async ({ request, params: { itemId, guid } }) => {
	try {
		const requestData = await request.formData();
		const obj = toObject(requestData);
		if (obj.method === "delete") {
			const data = await ApiService.delete_quest(obj.id);
			if (data.status === 200) AlertService.alert("success", "Data seved");
			return "delete";
		}
		if (obj?.id && obj.method !== "delete") {
			const data = await ApiService.update_quest(requestData);
			if (data.status === 200) AlertService.alert("success", "Data seved");
			return data;
		}
		const data = await ApiService.create_quest(requestData);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return data;
	} catch (error) {
		throw new Error(error);
	}
};
const Quest = Object.assign(Component, { loader, action });
export default Quest;
