import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
	useActionData,
	useFormAction,
	useLoaderData,
	useLocation,
	useNavigation,
	useParams,
} from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useAppSubmit } from "../../../helpers/hooks";
import CustomsInput from "../../Inputs/CustomsInput";
import UplodImg from "../../Inputs/UplodImg";
import ActionButton from "../../butons/ActionButton";
import {
	resouercesFormValidation,
	tasksFormValidation,
} from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const valuesName = ["name", "description", "imageName", "rate", "isFragmental"];

const ResouercesForm = ({}) => {
	const { lodardata, resouercesImg } = useLoaderData();
	const { state } = useLocation();
	const navigation = useNavigation();
	const { itemId, guid } = useParams();
	const actiondata = {};
	const formMethods = useForm({
		defaultValues: {
			name: lodardata?.data?.name || actiondata?.name || "",
			description:
				lodardata?.data?.description || actiondata?.description || "",
			imageName: lodardata?.data?.imageName || actiondata?.imageName || "",
			image: "",
			rate: lodardata?.data?.rate || actiondata?.rate || "",
			isFragmental:
				lodardata?.data?.isFragmental || actiondata?.isFragmental || false,
		},
		resolver: yupResolver(resouercesFormValidation),
	});

	const submit = useAppSubmit();
	const action = useFormAction();
	const {
		handleSubmit,
		setValue,
		control,
		register,
		watch,
		getValues,
		formState: { errors },
	} = formMethods;

	const isFragmental = useWatch({ control, name: "isFragmental" });

	useEffect(() => {
		if (isFragmental) {
			setValue("rate", "");
		}
	}, [isFragmental]);

	const onSubmit = async _data => {
		try {
			const formData = new FormData();
			Object.entries(_data).forEach(([key, value]) => {
				// if (!value) {
				// 	return formData.append(key, "");
				// }
				formData.append(key, value);
			});
			if (itemId) {
				formData.append("id", itemId);
			}
			submit(formData, { method: "POST", action });
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						<CustomsInput
							regName={"name"}
							type="text"
							label={"Resource name"}
							placeholder={"Name"}
						/>
						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						{state?.isDefault ? (
							<Form.Label>Rate: 1</Form.Label>
						) : (
							<>
								{!itemId && (
									<Fragment>
										<Form.Label>Is fragmentary</Form.Label>
										<Form.Check
											id="isFragmentary"
											className="mb-3 align-items-center"
											label="Yes"
											{...register("isFragmental")}
											type={"checkbox"}
										/>
									</Fragment>
								)}

								{(!isFragmental || lodardata?.data?.isDefault) && (
									<CustomsInput
										regName={"rate"}
										type="number"
										label={"Rate"}
										placeholder={"Rate"}
									/>
								)}
							</>
						)}
						<UplodImg
							options={resouercesImg}
							action={setValue}
							value={lodardata?.data?.imagePath || ""}
							required={true}
							labelValue="Resource Image"
							regName={"image"}
							regName2="imageName"
						/>
						<ActionButton buttonName="Save" />
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
};
export default ResouercesForm;
