import React from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";

const customId = () => {};

function CustomTable({ data = [] }) {
	if (Array.isArray(data) && data.length) {
		return (
			<Table striped bordered responsive>
				<thead>
					<tr>
						<th style={{ width: "10px" }} className="align-middle">
							#
						</th>
						<th className="align-middle">Name</th>
						<th className="align-middle" colSpan={4}>
							Description
						</th>
						<th className="align-middle" style={{ width: 100 }}>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
						<tr>
							<td style={{}}>1</td>
							<td>{item.name}</td>
							<td style={{ minWidth: "200px" }} colSpan={4}>
								{item.description}
							</td>
							<td className="d-flex gap-1 justify-content-around button_container">
								<div className="button_svg sucsses_boredr">
									<Link to="form">
										<AiOutlineEdit size={21} color="#198754" />
									</Link>
								</div>
								<div className="button_svg error_boredr">
									<AiOutlineDelete size={21} color="#dc3545" />
								</div>
							</td>
							<td colSpan={4}>
								<tr>
									<CustomTable data={item.categories} />
								</tr>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}
	return null;
}

export function CustomAccordion({ data = [] }) {
	if (Array.isArray(data) && data.length) {
		return data.map((item, i) => (
			<Accordion key={customId} defaultActiveKey="0" className="ms-3">
				<Accordion.Item eventKey={i}>
					<Accordion.Header>{item.name}</Accordion.Header>
					<Accordion.Body>
						<div>{item.description}</div>
						<div>
							<Button variant="primary">
								<Link to={`form/${item.id}`}>Edit</Link>
							</Button>
							<Button
								onClick={() => {
									// show(item.id);
								}}
								variant="primary">
								Delete
							</Button>
						</div>
						<CustomAccordion data={item.categories} />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		));
	}
	return null;
}

export default CustomTable;
