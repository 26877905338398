import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import { FailUpLoad } from "../../helpers/helper";
import { Button } from "react-bootstrap";
import { useError } from "../../helpers/hooks";
import { Controller } from "react-hook-form";

const validFileTypes = ["jpg", "png", "jpeg", "JPEG", "JPG", "PNG"];

const MultipleImageInput = ({
	action = () => {},
	regName,
	regName2 = "",
	className = "",
	values = [],
	imageNames = [],
	deleteImages = [],
	images = [],
	deleteRegName = "deleteImages",
	id = "icon-button-file",
	labelValue = "",
	required = false,
}) => {
	const errorMessage = useError(regName);
	const errorMessage2 = useError(regName2);
	const [files, setFails] = useState(values);
	return (
		<div className={`${className}`}>
			<input
				color="primary"
				type="file"
				multiple
				onChange={async e => {
					const arr = [];
					const upload_arr = [];

					for (let i = 0; i < e.target.files.length; ++i) {
						const file = e.target.files[i];
						const fileName = await new FailUpLoad().readFile(
							e.target.files[i],
							validFileTypes,
						);
						arr.push(file);
						upload_arr.push(fileName);
					}
					action(regName, [...images, ...arr], {
						shouldValidate: true,
						shouldDirty: true,
					});
					setFails([...files, ...upload_arr]);
					action(regName2, files, {
						shouldValidate: true,
						shouldDirty: true,
					});
				}}
				id={id}
				accept=".jpg,.png,jpeg,.JPEG,.JPG,.PNG,"
				style={{ display: "none" }}
			/>

			{labelValue ? (
				<label className="mb-2 form-label">
					{labelValue}
					{required ? <span className="required ms-1">*</span> : ""}
				</label>
			) : null}
			<br />
			<div className="d-flex gap-2 flex-wrap align-self-stretch">
				{!!files.length &&
					files.map((el, i) => (
						<div
							key={i}
							className="position-relative d-inline-flex  flex-column p-2 border rounded"
							style={{ display: "inline-block" }}>
							<img className="uplod_img p-1 " src={el} srcSet={el} alt="file" />
							{/* <div
								className="position-absolute  cursor-pointer"
								style={{
									top: 5,
									right: 5,
								}}
								onClick={() => {
									const deleteimagePath = imageNames?.find(img => {
										if (el.includes(img)) return img;
									});
									let newImageNames = imageNames;
									if (deleteimagePath) {
										newImageNames = imageNames?.filter(
											img => img !== deleteimagePath,
										);
										action(deleteRegName, [...deleteImages, deleteimagePath]);
									}
									let newImagePathe = images;
									const ind = files
										.filter(url => url.startsWith("blob:"))
										.indexOf(el);
									if (ind !== -1) {
										newImagePathe = newImagePathe?.filter((_, i) => i !== ind);
									}
									setFails(prev => {
										return prev.filter((_, index) => index !== i);
									});

									action(regName2, newImageNames, {
										shouldValidate: true,
										shouldDirty: true,
									});
									action(regName, newImagePathe, {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}>
								<AiOutlineDelete size={29} color="#dc3545" />
							</div> */}
							<Button
								size="sm"
								onClick={() => {
									const deleteimagePath = imageNames?.find(img => {
										if (el.includes(img)) return img;
									});
									let newImageNames = imageNames;
									if (deleteimagePath) {
										newImageNames = imageNames?.filter(
											img => img !== deleteimagePath,
										);
										action(deleteRegName, [...deleteImages, deleteimagePath]);
									}
									let newImagePathe = images;
									const ind = files
										.filter(url => url.startsWith("blob:"))
										.indexOf(el);
									if (ind !== -1) {
										newImagePathe = newImagePathe?.filter((_, i) => i !== ind);
									}
									setFails(prev => {
										return prev.filter((_, index) => index !== i);
									});

									action(regName2, newImageNames, {
										shouldValidate: true,
										shouldDirty: true,
									});
									action(regName, newImagePathe, {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
								variant="outline-danger">
								Remove
							</Button>
						</div>
					))}
			</div>
			<Button
				variant="primary"
				style={{ cursor: "pointer !important" }}
				className={`d-flex align-items-center px-3 ${
					!!errorMessage ? "button_error" : ""
				} ${files.length ? "mt-2" : ""}`}
				onClick={() => {
					if (!id) return false;
					document.getElementById(id)?.click();
				}}>
				<AiOutlineDownload size={21} className="me-2" />
				<span>Upload</span>
			</Button>
			{(!!errorMessage || errorMessage2) && (
				<span className="helper_text">{errorMessage || errorMessage2}</span>
			)}
		</div>
	);
};

export default MultipleImageInput;
