import { useFormAction, useLoaderData } from "react-router-dom";
import ApiService from "../../helpers/api";
import { useAppSubmit } from "../../helpers/hooks";
import AlertService from "../../helpers/alertService";
import CardsResouerce from "../../components/card/CardsResouerce";
import AddButton from "../../components/butons/AddButton";
import { useContext, useEffect } from "react";
import { TimerContext } from "../../App";

const Component = () => {
	const submit = useAppSubmit(),
		action = useFormAction();
	const data = useLoaderData();
	const { setIsUpdateTimer } = useContext(TimerContext);
	useEffect(() => {
		if (data && data.data && data.data.length) {
			setIsUpdateTimer(true);
		}
	}, [data]);

	const onSubmit = async id => {
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`).then(res => {
			onSubmit(id);
		});
	return (
		<div>
			<AddButton buttonTitle="resource" to="form" pageTitle="Resources" />
			<CardsResouerce data={data.data} show={show} />
		</div>
	);
};

const action = async ({ request, params }) => {
	try {
		const formData = await request.formData();
		const id = Object.fromEntries(formData).id;
		const data = await ApiService.deleteResource(id);
		if (data.status === 200) {
			AlertService.alert("success", "Data seved");
		}
		return data;
	} catch (error) {
		throw new Response("Not Found", { status: 404 });
	}
};

const loader = async ({ request, params: { guid } }) => {
	try {
		const data = await ApiService.getAllresource();

		return data;
	} catch (error) {
		return error;
	}
};

const Resouerces = Object.assign(Component, { loader, action });
export default Resouerces;
