import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams, useSubmit } from "react-router-dom";

export const useFormRegister = regName => {
	const formMethods = useFormContext();
	const register = regName ? formMethods?.register(regName) : null;

	return register;
};

export const useAppSubmit = () => {
	const submit = useSubmit();
	return (target, options) =>
		submit(target, { ...options, encType: "multipart/form-data" });
};

export const useError = regName => {
	const formMethods = useFormContext();
	const splitedName = regName?.split(".");
	const thisError =
		splitedName && formMethods.formState.errors?.[splitedName[0]];
	const errorMessage = useMemo(() => {
		if (regName.includes(".")) {
			// formMethods.trigger(splitedName[0]);
			if (regName.split(".").length === 2) {
				if (formMethods.formState.errors[regName.split(".")[0]]) {
					const err =
						formMethods.formState.errors[regName.split(".")[0]][
							regName.split(".")[1]
						];
					return (
						formMethods.formState.errors[regName.split(".")[0]] &&
						err &&
						err.message
					);
				}
			}
			if (regName.split(".").length === 3) {
				if (Boolean(formMethods.formState.errors[regName.split(".")[0]])) {
					if (
						Boolean(thisError) &&
						Array.isArray(thisError) &&
						Boolean(thisError[+splitedName[1]?.split("[")[1]?.split("]")[0]]) &&
						Boolean(
							thisError[+splitedName[1]?.split("[")[1]?.split("]")[0]][
								splitedName[2]
							]?.message,
						)
					) {
						return thisError[splitedName[1]?.split("[")[1]?.split("]")[0]][
							splitedName[2]
						]?.message;
					} else return;
				} else return;
			}
		} else if (Boolean(formMethods?.formState?.errors?.[regName]?.message)) {
			// formMethods.trigger(regName);
			return formMethods?.formState?.errors?.[regName]?.message;
		} else return;
	}, [formMethods?.formState?.errors, regName, splitedName, thisError]);

	return errorMessage;
};

export const useToken = () => {
	const params = useParams();
	return params.guid;
};
