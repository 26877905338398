import ApiService, { baseURL } from "../../helpers/api";
import {
	redirect,
	useActionData,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import ResouercesForm from "../../components/forms/resouerce/ResouercesForm";
import { useContext, useEffect, useState } from "react";
import FragmentsForm from "../../components/forms/resouerce/FragmentsForm";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";
import { Col, Row } from "react-bootstrap";
// form/${actiondata?.data.id}
const Component = () => {
	const actiondata = useActionData(),
		{ lodardata, resouercesImg, fragmentImg } = useLoaderData(),
		[data, setData] = useState(lodardata?.data),
		navigate = useNavigate();
	const { guid } = useParams();
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		setIsUpdateTimer(true);
	}, [lodardata, resouercesImg, fragmentImg]);
	useEffect(() => {
		if (lodardata && lodardata.data) setData(lodardata?.data);
	}, [lodardata]);
	useEffect(() => {
		if (actiondata && actiondata.data && actiondata.data.rate) {
			navigate(`/resources/${guid}`);
		} else if (actiondata && actiondata.data) {
			navigate(`/resources/${guid}/form/${actiondata.data.id}`);
		} else if (actiondata === "update") {
			navigate(`/resources/${guid}`);
		}
	}, [actiondata]);
	return (
		<FormLayout pageTitle="Resource Form" backTo={"resources"}>
			<ResouercesForm />
			{data && data.fragments && (
				<FragmentsForm data={data} setData={setData} />
			)}
		</FormLayout>
	);
};
const action = async ({ request, params: { itemId } }) => {
	try {
		const requestData = await request.formData();
		if (itemId) {
			const data = await ApiService.updateResource(requestData);
			if (data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_resource(requestData);
		// redirect(`${data.id}`);
		return data;
	} catch (error) {
		return null;
	}
};
const loader = async ({ request, params: { itemId } }) => {
	try {
		// ("Resource,Fragment");
		const resouercesImg = await ApiService.getStaicFailes("Resource").then(
			res => {
				if (res.data && res.data.length) {
					return res.data.map(el => {
						return {
							value: el,
							label: `${baseURL}/${el}`,
						};
					});
				} else return [];
			},
		);
		const fragmentImg = await ApiService.getStaicFailes("Fragment").then(
			res => {
				if (res.data && res.data.length) {
					return res.data.map(el => {
						return {
							value: el,
							label: `${baseURL}/${el}`,
						};
					});
				} else {
					return [];
				}
			},
		);
		if (itemId) {
			const lodardata = await ApiService.getResource(itemId);
			return { lodardata, resouercesImg, fragmentImg };
		}
		return { resouercesImg, fragmentImg };
	} catch (error) {
		return error;
	}
};
const ResouercesForms = Object.assign(Component, { action, loader });

export default ResouercesForms;
