import { Outlet, useNavigation, useParams } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { TbDeviceGamepad2 } from "react-icons/tb";
import Timer from "../timer/Timer";

const Root = () => {
	const navigation = useNavigation();

	return (
		<>
			<Navbar bg="primary" data-bs-theme="dark">
				<div className="container-xxl d-flex justify-content-between align-items-center text-white py-1 flex-wrap">
					<div className="d-flex align-items-center">
						<TbDeviceGamepad2 size={35} color="#ffffff" />
						<h4 className="text-white ms-2 mb-0">Gamification</h4>
					</div>
					{/* <Nav className="gap-3">
						<Nav.Item>
							<Link to={`/resouerces/${token}`}>Resouerces</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/quest/${token}`}>Tasks</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/badges/${token}`}>Badges</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/leaderbord/${token}`}>Leaderbord</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/shop-item/${token}`}>Shop Item</Link>
						</Nav.Item>
						<Nav.Item>
							<Link to={`/category/${token}`}>Category</Link>
						</Nav.Item>
					</Nav> */}
				</div>
			</Navbar>
			{navigation.state !== "idle" && (
				<div
					className="loader-line"
					style={{
						position: "sticky",
					}}></div>
			)}
			<div className="container-xxl mt-3 mb-5">
				<Outlet />
			</div>
			<Timer />
		</>
	);
};

export default Root;
