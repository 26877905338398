import React, { useContext, useMemo, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Form } from "react-router-dom";
import CustomSelect from "../../Inputs/CustomSelect";
import CustomsInput from "../../Inputs/CustomsInput";
import ApiService from "../../../helpers/api";
import AlertService from "../../../helpers/alertService";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { validetionFunctions } from "../../../helpers/validation";
import { TimerContext } from "../../../App";

const QuestResources = ({
	data = [],
	id = "",
	setData,
	resourceSelectValue = [],
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [shoewSelatc, setShowSelect] = useState(true);
	const { setIsUpdateTimer } = useContext(TimerContext);

	const [defaultValues, setDefaultValues] = useState({
		resourceId: "",
		resourceAmount: "",
	});
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(
			object().shape({
				resourceId: validetionFunctions.select,
				resourceAmount: validetionFunctions.count("Amout"),
			}),
		),
	});
	const { handleSubmit, setValue, reset } = formMethods;
	const tableData = useMemo(() => {
		if (data && resourceSelectValue) {
			return data?.map(el => {
				const value = resourceSelectValue.find(
					item => item.value === el?.resourceId,
				);
				return {
					...el,
					...value,
				};
			});
		}
		return [];
	}, [data, resourceSelectValue]);

	const onSubmit = async _data => {
		setIsLoading(true);

		if (isUpdate) {
			await ApiService.update_quest_resource(_data)
				.then(res => {
					if (res.data) {
						AlertService.alert("success", "Data seved");
						const newData = data?.map(el => {
							if (el.id === res.data.id) {
								return res.data;
							}
							return el;
						});
						setData(prev => {
							return {
								...prev,
								resources: newData,
							};
						});
						setIsUpdateTimer(true);
					}

					onClose();
				})
				.catch(error => {
					Object.keys(error).forEach(key => {
						if (Array.isArray(error[key])) {
							error[key].forEach(el => {
								AlertService.alert("warning", el);
							});
						}
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
			return;
		} else {
			await ApiService.create_quest_resource(_data)
				.then(res => {
					if (res.data) {
						AlertService.alert("success", "Data seved");
						setData(prev => {
							const newData = [...data, res.data];
							return {
								...prev,
								resources: prev.resources ? newData : [],
							};
						});
					}
					setIsUpdateTimer(true);
					onClose();
				})
				.catch(error => {
					Object.keys(error).forEach(key => {
						if (Array.isArray(error[key])) {
							error[key].forEach(el => {
								AlertService.alert("warning", el);
							});
						}
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		// submit(formData, { method: "POST", action });
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				ApiService.delete_qust_resource(id)
					.then(res => {
						if (res.status === 200) {
							AlertService.alert("success", "Data seved");
							setData(prev => {
								const newData = prev.resources.filter(el => el.id !== id);
								return {
									...prev,
									resources: prev.resources ? newData : [],
								};
							});
							setIsUpdateTimer(true);
						} else {
							AlertService.alert("error", "Sorry, something went wrong!");
						}
					})
					.catch(error => {
						AlertService.alert("error", "Sorry, something went wrong!");
					})
					.finally(() => {});
			})
			.catch(() => {});
	const onClose = () => {
		reset();
		setOpen(!open);
		setIsUpdate(false);
		setShowSelect(true);
		setDefaultValues({
			badgeId: id,
			resourceId: "",
			resourceAmount: "",
		});
	};
	// questResources;
	if (id) {
		return (
			<>
				<Modal
					show={open}
					onHide={onClose}
					backdrop="static"
					centered
					keyboard={false}>
					<FormProvider {...formMethods}>
						<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{!isUpdate ? "Create" : "Update"}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{shoewSelatc ? (
									<CustomSelect
										required={true}
										regName={`resourceId`}
										cb={setValue}
										label={"Select the resources"}
										options={resourceSelectValue}
									/>
								) : (
									<h3>{defaultValues.label}</h3>
								)}
								<CustomsInput
									regName={`resourceAmount`}
									type="number"
									required={true}
									label={"Set amount of the resources"}
									placeholder={"Amount"}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button
									disabled={isLoading}
									variant="secondary"
									onClick={onClose}>
									Close
								</Button>
								<Button disabled={isLoading} type="submit" variant="primary">
									{isLoading ? (
										<span className="d-flex align-items-center gap-2">
											<span>Loading...</span>
											<Spinner animation="border" role="status" size="sm" />
										</span>
									) : (
										"Save"
									)}
								</Button>
							</Modal.Footer>
						</Form>
					</FormProvider>
				</Modal>
				<div className="d-flex justify-content-between align-items-center p-2">
					<h5>Resources</h5>
					<Button
						variant="light"
						onClick={() => {
							setOpen(true);
							setValue("questId", id);
						}}>
						<MdOutlineAddCircleOutline size={21} />
					</Button>
				</div>
				<div>
					<Table
						bordered
						responsive
						style={{
							backgroundColor: "#fff",
						}}
						className={`text-center mt-3`}>
						<thead>
							<tr>
								<th style={{ width: "10px" }} className="align-middle">
									#
								</th>
								<th className="align-middle">Name</th>
								<th className="align-middle">Amount</th>
								<th style={{ width: 100 }} className="align-middle">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(tableData) &&
								tableData.map((item, index) => {
									return (
										<React.Fragment key={item.id}>
											<tr>
												<td className="align-middle">{index + 1}</td>
												<td className="align-middle">{item.label}</td>
												<td className="align-middle">{item.resourceAmount}</td>
												<td className="d-flex gap-1 w-full justify-content-around align-items-center button_container">
													<div
														className="button_svg sucsses_boredr"
														onClick={() => {
															Object.entries(item).forEach(([key, value]) => {
																if (key !== "label" && key !== "value") {
																	setValue(key, value);
																}
															});
															setValue("questId", id);
															setIsUpdate(true);
															setDefaultValues(item);
															setOpen(true);
															setShowSelect(false);
														}}>
														<AiOutlineEdit size={21} color="#198754" />
													</div>
													<div
														className="button_svg error_boredr"
														onClick={() => {
															show(item.id);
														}}>
														<AiOutlineDelete size={21} color="#dc3545" />
													</div>
												</td>
											</tr>
										</React.Fragment>
									);
								})}
						</tbody>
					</Table>
				</div>
			</>
		);
	}
	return null;
};

export default QuestResources;
